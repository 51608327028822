.gateway_settings_form {

  /* spacing between elements  */
  .row .col-md-* {
    margin-top: 20px;
  }

  .submit_btn {
    padding-top: 15px;
  }

 }

.gateway-settings {
  .show-hidden {
    a.glyphicon {
      color: #bbb;
      font-size: 18px;

      &.active {
        color: #333;
      }
    }

    float: right;
    margin-right: 20px;
    margin-top: 15px;
  }

  .table {
    width: 100%;

    th, td {
      padding-left: 15px;
    }

    td.key {
      width: 25%;
      padding-right: 15px;
      color: #888;
      text-align: right;
    }

    td.actions {
      a {
        margin-right: 10px;
      }
    }

    input[type="text"], textarea {
      padding: 2px 5px;
      border: 1px solid #ccc;
    }

    input[type="text"] {
      margin-top: -5px;
      width: 150px;
    }

    textarea {
      margin-top: -3px;
      width: 400px;
      min-height: 70px;
      vertical-align: top;
    }

    p.help {
      color: #999;
      font-size: 10px;
      margin-bottom: -5px;
    }
  }
}
