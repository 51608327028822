.vsm-config-form {
  [class^="col-md-"]{
    margin-top: 2px;
  }
  .row {
    margin-left: 5px;
  }

  .spaced-row {
    margin-bottom: 20px;
  }

  hr {
    border-width: 1px;
    border-style: solid;
  }

  :disabled {
    color: #888888;
  }

  .vsm-alert {
    color: red;
  }

  .small-note {
    font-size: 10px;
  }

  .test_endpoint_responses_link {
    float: right;
  }
}
