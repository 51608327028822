.header {
  text-align: center;
}

.request_performance, .disclosure_report, .provider_auth_failures {
  table {
    margin:auto;
    border: 0px;
    width: 95%;
  }

  tr, td, th{
    text-align: center;
    border: 1px solid black;
    th, td {
      padding: 10px;
    }
    th {
      background-color: #e7e7e7;
    }
  }
}

.request_performance {
  padding-bottom: 20px;
  table {
    width: 98%;
  }
}

.disclosure_report{
  div {
    float: left;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 500px;
  }

  .disclosure_responses {
    width: 25%;
  }
  .disclosing_states {
    width: 25%;
  }
  .disclosure_errors {
    width: 50%;
  }
}

.provider_auth_failures {
  table {
    width: 25%;
  }
}

.export_csv {
  font-weight: bold;
  border: 0px solid black !important;
  td {
    text-align: left;
    border: 0px solid black !important;
  }
}
