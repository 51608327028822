.view_permissions_content {
  table {
    border: 1px solid black;
  }

  table tr th,
  table tr td {
    padding: 10px;
    border-bottom: 1px solid black;
  }

  table tr th {
    background-color: #eee;
  }

  table tr td::first-child {
    text-align: right;

  }
}
