.provider_auth_file {
    border: 2px solid #AAA;
    border-radius: 4px;
    padding: 5px 10px;
    margin: 2px;
    background: #DDD;
    display: inline-block;
}

.provider_auth_file:hover {
    background: #CCC;
}

.provider_auth_file:active {
    background: #CCF;
}

.provider_auth_file :invalid + span {
    color: #A44;
}

.provider_auth_file :valid + span {
    color: #4A4;
}

.licensee-tile-upgrade-file {
  background-color: #eee;
  border: 2px solid #ccc;
  border-radius: 4px;
  padding: 30px 20px 30px 20px;
  width: 100%;
}

.licensee-tile-upgrade-panel-spacer {
  height: 77px;
}
