table {
  thead {
    th.states-comm-th {
      padding: 5px 15px;
    }
  }

  tbody {
    td.states-comm-td {
      padding: 15px;
    }
  }
}

.label-alt.states-comm-label {
  display: inline-block;
  margin-top: -5px;
  margin-bottom: -5px;
  padding: 4px 0;
  width: 80px;
  text-align: center;
  margin-right: 10px;
}

.states-comm-enabled-details {
  font-size: 12px;
}
