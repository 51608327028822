#pagination_filter {
  width: 100%;
  margin: 0px;
  padding-bottom: 75px;

  #pagination {
    float: left;
    position: absolute;

    .pagination {
      padding-left: 0;
      margin: 0;
    }

    #pagination_totals {

    }
  }

  #filter_form {
    float: right;
    width: 350px;
  }
}
