body {
  background-color: #fff;
  color: #333;
  font-family: verdana, arial, helvetica, sans-serif;
  font-size: 13px;
  line-height: 18px;
}

p, ol, ul, td {
  font-family: verdana, arial, helvetica, sans-serif;
  font-size: 13px;
  line-height: 18px;
}

pre {
  background-color: #eee;
  padding: 10px;
  font-size: 11px;
}

a {
  color: #000;
  &:visited {
    color: #666;
  }
  &:hover {
    color: #000;
  }
}

a.btn {
  color: #FFF;
  &:visited {
    color: #FFF;
  }
  &:hover {
    color: #FFF;
  }
}

a.default {
  color: #0d6efd;
  &:visited {
    color: #0d6efd;
  }
  &:hover {
    color: #0d6efd;
  }
}

a.glyphicon {
  color: #000;
  text-decoration: none;
  &:visited {
    color: #000;
  }
  &:hover {
    color: #000;
  }
}

/* START Fixes fonts on glyphicon classes to use parent font. */
.glyphicon {
  color: inherit;
  font-family: verdana, arial, helvetica, sans-serif !important;
  font-size: inherit;
  line-height: inherit;
}
.glyphicon:before {
  font-family: 'Glyphicons Halflings';
}
/* END Fixes fonts on glyphicon classes to use parent font. */

div {
  &.field, &.actions {
    margin-bottom: 10px;
  }
}

#notice {
  color: green;
}

.field_with_errors {
  padding: 2px;
  background-color: red;
  display: table;
}

td .field_with_errors {
  display: inline-block;
}

.red {
  color: red;
}

#error_explanation {
  width: 450px;
  border: 2px solid red;
  padding: 7px;
  padding-bottom: 0;
  margin-bottom: 20px;
  background-color: #f0f0f0;
  h2 {
    text-align: left;
    font-weight: bold;
    padding: 5px 5px 5px 15px;
    font-size: 12px;
    margin: -7px;
    margin-bottom: 0px;
    background-color: #c00;
    color: #fff;
  }
  ul li {
    font-size: 12px;
    list-style: square;
  }
}


$secondnav-menu-background-color-selected: #E7E7E7;
$secondnav-menu-background-color-hover: #F8F8F8;
$secondnav-menu-border-radius: 10px;

ul.secondnav {
  margin: 0; padding: 0;
  float: left;
  list-style: none;
  position: relative; /*--Set relative positioning on the unordered list itself - not on the list item--*/
  font-size: small;
}

ul.secondnav li a {
  padding-left: 15px;
  padding-right: 15px;
  width: auto;
  display: block;
}

ul.secondnav li a {
  color: #000;
  &:visited {
    color: #666;
  }
  &:hover {
    color: #000;
    background-color: $secondnav-menu-background-color-hover;
    border-radius: $secondnav-menu-border-radius;
  }
}

ul.secondnav li.chosen a {
  background-color: $secondnav-menu-background-color-selected;
  border-radius: $secondnav-menu-border-radius;
  &:hover {
    background-color: $secondnav-menu-background-color-selected;
    border-radius: $secondnav-menu-border-radius;
  }
}



.wide-input textarea {
  width: 400px;
  margin:0 0 10 0px;
}

.wide-input input[type='text'] {
  width: 400px;
  margin:0 0 10 0px;
}


.form-group {
  .field_with_errors {
    padding: 0;
    background-color: transparent;
    display: block;

    label {
      color: red;
    }
  }
}


dl.dl-list {
  display: flex;
  flex-flow: row wrap;

  dt {
    flex-basis: 20%;
    padding: 2px 4px;
    text-align: right;
    color: #000;
    min-width: 190px;
    white-space: nowrap;
  }

  dd {
    flex-basis: 70%;
    flex-grow: 1;
    margin: 0;
    padding: 2px 4px;
  }
}
