.labelmaker { 
  color: #335593; 
  text-align: right; 
}
.list-line-even {
  background-color: #eaffff; 
}
.list-line-odd { 
  background-color: white; 
}

#user_edit_form { 
  margin-right: auto; 
  margin-left: auto;
  width: 550px; 
}

.highlight { 
  background-color:darkblue; 
}
