@import 'variables';

.licensee_detail_user_alert {
  color: $warning-color;
}

.list-group-item.active {
  background-color: grey;
}

.list-group-item.active a:visited {
  color: white;
}

.bypass-auth {
  border: 2px solid black;
}

.eula_management {
  width: 80%;
  float: left;
}

.form_input_details {
  font-size: 11px;
  margin-left: 17px;
  line-height: 16px;
}

.form_input_details2 {
  font-size: 11px;
  margin-left: 5px;
  line-height: 16px;
  color: #888;
}

span.error {
  color: $error-color;
}

.configure_report_layout_title {
  margin-top: 0px;
  margin-bottom: 30px;
  margin-left: 25px;
}

.licensee-search-by {
  background-color: rgb(109,156,190);
  a {
    color: white !important;
  }
}

#licensee_filter_data {
  height: 37px;
}

.licensee-bhcc {
  option[selected="selected"] {
    background: silver;
  }
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #555;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
  margin: auto;
}

.npi-dea-modal-button {
  background-color: transparent;
  border-color: transparent;
  color: black !important;
}

.copy-button {
  background-color: transparent;
  border-color: transparent;
  color: black;
}



/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
