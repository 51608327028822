.user_management_table {

  tr:nth-child(1) th {
    font-size: 24px;
    margin-left: 5px;
  }
}

.user_management_edit {

  .user_management_fieldset {
    max-width: 550px;
  }

  .user_management_information_box {
    margin-top: 30px;
    border: 1px solid #eee;
    background-color: #f3f3f3;
    border-radius: 10px;
    width: 650px;
  }
}

