#view_request_destination {
  table {
    border: 1px solid black;
  }

  table tr th,
  table tr td {
    padding: 10px;
    border-bottom: 1px solid black;
  }

  table tr th {
    background-color: #eee;
  }

  table tr td::first-child {
    text-align: right;

  }
}

.settings_icon_complete {
  color: #00AA00;
}

.link-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
