.modal-title {
  font-weight: bold;
  font-size: 18px;
}

.close {
  position: relative;
  top: 0px;

  &.position-adjustment {
    top: -25px;
  }
}

.modal-body {
  h5:first-of-type {
    font-weight: bold;
    font-size: 16px;
    padding-top: 0px;
  }

  h5 {
    font-weight: bold;
    font-size: 16px;
    padding-top: 15px;
  }

  table {
    width: 100%;
    margin-top: 10px;
  }

  th {
    border-bottom: 1px solid #242424;
  }

  td {
    border-bottom: 1px solid #e5e5e5;
  }

  .request_selected {
    background-color: #98FB98;
  }

  .report_request {
    cursor: pointer;
  }

  #pick_list_error {
    color: #a94442;
  }

  #request_errors {
    width: 100%;
    padding: 15px;
    background-image: linear-gradient(180deg, #f2dede 0, #e7c3c3);
    border: 1px solid #dca7a7;
    border-radius: 4px;
    text-align: center;
  }

  .report_request:not(.request_selected):hover {
    background-color: #ffff99;
  }
}
