.checkbox-inline {
  white-space: nowrap;
}

.title {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 1.8em;
  font-weight: bold;
}

.hover:hover {
  cursor: pointer;
}

.content {
  margin: 20px;
}

.not-bold {
  font-weight: normal;
}

.top-spacing {
  margin-top: 15px;
}

.eula_management {
  .eula_state {
    width: 48%;
    margin: 5px;
    border-radius: 10px;
    padding: 10px;
    margin: 5px;
  }

  .title_state_name {
    font-size: 1.8em;
    font-weight: bold;
    color: #3071A9;
  }
}

.select-size-wide {
  max-width: 350px;
}

.pencil-edit {
  color: gray;
}

.states-form-table-row {
  margin-bottom: 5px;
}

.states-form-table-label {
  display: inline-block;
  font-size: 12px;
  width: 150px;
}

a.edit-state-link, a.edit-state-link:visited {
  color: #777;
  font-size: 11px;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #555;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
  margin: auto;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
