.state_bhcc_settings {
  table {
    thead {
      th.bhcc-settings-th {
        padding: 5px 15px;
      }
    }

    tbody {
      td.bhcc-settings-td {
        padding: 15px;
      }
    }
  }
}
