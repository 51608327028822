fieldset fieldset>legend {
  font-size: 18px;
};

.icon-primary {
  color: #428bca;
}

.icon-success {
  color: #5CB85C;
}

.icon-warning {
  color: yellow;
}

.font-fixed-width {
  font-family: Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New, monospace;
}

.label-alt {
  color: black;
  background-color: #e6e6e6;
  padding: 5px 15px;
  border-radius: 2px;
  border: 1px solid #ccc;
  font-size: 12px;

  &.label-success {
    color: white;
    background-color: #006835;
    border-color: #006835;
  }
}

.link-alt {
  color: #427aad;

  &:visited {
    color: #427aad;
  }
}

a.back-link {
  font-size: 24px;
  color: black;

  .glyphicon {
    font-size: 20px;
  }

  &:hover {
    text-decoration: none;
    color: black;
  }
}

a.btn-default {
  color: black;

  &:visited {
    color: black;
  }
}

.panel-alt {
  margin-top: 30px;
  padding: 20px 30px;
  background-color: #f5f5f5;

  h4 {
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 16px;
  }
}

.tooltip {
  font-weight: normal;
}

.terms-of-use-link {
  font-size: 12px;
  padding: 0px 4px;
}

.privacy-policy-link {
  font-size: 12px;
  padding-left: 4px;
}

.add-height {
 min-height: 75vh;
}
