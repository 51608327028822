#gw-svc-cfg-tab div.field {
  margin-top: 0.25em;
}

@media screen {
  #gw-svc-cfg-tab legend {
    display: block;
    width: 100%;
    margin-bottom: 0.6em;
    font-size: 21px;
    line-height: inherit;
    color: #333333;
    border-bottom: 1px solid #d0d0d0;
  }
}